import { useConfig } from 'providers';
import { FC } from 'react';
import { BoatDbWhite, TheBoatAppWhite } from '../../../CustomIcons';
import styles from './Address.module.scss';
import { AddressProps } from './Address.props';

const Address: FC<AddressProps> = () => {
  const { appCode } = useConfig();

  return (
    <div className={styles.container}>
      <div className={styles.containerLogo}>
        {appCode === 'TBA-WEB' && <TheBoatAppWhite />}
        {appCode === 'TBD-WEB' && <BoatDbWhite />}
      </div>

      <p className={styles.containerLogo}>
        Company Registration Nr: 11835484
        <br />
        VAT Registration Nr: 331451241
      </p>

      <p className={styles.containerLogo}>
        Marine Data Cloud Ltd <br />
        Registered in England & Wales <br />
        at 71-75, Shelton Street, Covent Garden, London, WC2H 9JQ, UK
      </p>
    </div>
  );
};

export { Address };
