import Header from '@components/Header';
import MetaData from '@components/MetaData';
import { Layout as AntLayout } from 'antd';
import Head from 'next/head';
import { Footer } from 'sailor-ui/Footer/Footer.component';
import styles from './Layout.module.scss';
import { LayoutProps } from './Layout.props';

const Layout: React.FC<LayoutProps> = ({ children, meta }) => (
  <div>
    <MetaData {...meta} />
    <Head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    </Head>

    <AntLayout>
      <Header />

      <div className={styles.content}>{children}</div>

      <Footer />
    </AntLayout>
  </div>
);

export { Layout };
