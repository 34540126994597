import { FC } from 'react';
import styles from './Copyright.module.scss';
import { CopyrightProps } from './Copyright.props';

const currentYear = new Date().getFullYear();

const Copyright: FC<CopyrightProps> = () => {
  const marineDataCloudUrl = (
    <a href="http://marinedatacloud.com/" target="_blank" rel="noreferrer">
      Marine Data Cloud™
    </a>
  );

  return (
    <div className={styles.container}>
      <p className={styles.containerText}>
        All Rights Reserved © 2019-
        {currentYear} {marineDataCloudUrl}
      </p>
    </div>
  );
};

export { Copyright };
