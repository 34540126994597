import { FC } from 'react';
import { FacebookIcon, InstagramIcon, LinkedinIcon, RedditIcon, TwitterIcon, YoutubeIcon } from '../../../CustomIcons';
import styles from './Header.module.scss';
import { HeaderProps } from './Header.props';

const Header: FC<HeaderProps> = () => {
  const socialItems = [
    {
      url: 'https://www.facebook.com/marinedatacloud/',
      icon: <FacebookIcon />,
    },
    {
      url: 'https://twitter.com/MarineDataCloud',
      icon: <TwitterIcon />,
    },
    {
      url: 'https://www.linkedin.com/company/marinedatacloud',
      icon: <LinkedinIcon />,
    },
    {
      url: 'https://www.reddit.com/user/MarineDataCloud/',
      icon: <RedditIcon />,
    },
    {
      url: 'https://www.instagram.com/marinedatacloud',
      icon: <InstagramIcon />,
    },
    {
      url: 'https://www.youtube.com/channel/UCR5zZQwHQTE9uhDXdHgrzcA',
      icon: <YoutubeIcon />,
    },
  ];

  return (
    <div className={styles.container}>
      <ul className={styles.containerMenu}>
        {socialItems.map((item) => {
          const { url, icon } = item;

          return (
            <li className={styles.containerItem} key={item.url}>
              <a href={url} target="_blank" rel="noreferrer" className={styles.containerItemLink}>
                {icon}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export { Header };
