import { Menu } from 'antd';
import Link from 'next/link';
import styles from './Header.module.scss';
import cn from 'classnames';

const menuItems = [
  {
    key: 'home',
    label: <Link href="/">Home</Link>,
  },
  {
    key: 'boat',
    label: <Link href="/category/boat">Boat</Link>,
  },
  {
    key: 'yacht',
    label: <Link href="/category/yacht">Yacht</Link>,
  },
  {
    key: 'news',
    label: <Link href="/news">News</Link>,
  },
];

type Props = {
  responsive: boolean;
};

export const NavMenu = ({ responsive }: Props) => (
  <>
    <Menu
      mode={responsive ? 'inline' : 'horizontal'}
      theme={responsive ? 'dark' : 'light'}
      className={cn({ [(styles.leftMenuDesktop, styles.menu)]: responsive })}
      items={menuItems}
    />
  </>
);
