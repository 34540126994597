import { Address } from './components/Address';
import { Header } from './components/Header';
import { Copyright } from './components/Copyright';

import styles from './Footer.module.scss';

const Footer: React.FC = () => (
  <footer className={styles.container}>
    <Header />

    <Address />

    <Copyright />
  </footer>
);

export { Footer };
