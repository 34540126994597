import { LeftOutlined } from '@ant-design/icons';
import { Button, Drawer, Grid } from 'antd';
import Link from 'next/link';
import React, { useEffect } from 'react';

type Props = {
  onClose: () => void;
  visible: boolean;
  className?: string;
  goBack?: boolean;
  head?: JSX.Element;
  closable?: boolean;
  children: React.ReactNode;
};

export const MainDrawer: React.FC<Props> = ({
  onClose,
  visible,
  className,
  goBack = true,
  children,
  head,
  closable = true,
}) => {
  const { xl } = Grid.useBreakpoint();

  useEffect(() => {
    if (xl) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xl]);

  return (
    <Drawer
      title={
        head || (
          <Link href="/" className="headerLogo">
            <img src="/images/theboatdb-logo-white.svg" alt="TheBoatDB Logo" />
          </Link>
        )
      }
      placement="right"
      className={className}
      closable={closable}
      onClose={onClose}
      open={visible}
    >
      {goBack && (
        <Button
          key="more-go-back"
          type="text"
          onClick={onClose}
          icon={<LeftOutlined />}
          style={{
            display: 'flex',
            alignItems: 'center',
            color: 'var(--gray-1)',
            marginBottom: 8,
          }}
        >
          Go Back
        </Button>
      )}

      <>{children}</>
    </Drawer>
  );
};
